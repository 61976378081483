<template>
  <v-app>
    <v-app-bar app dense flat class="primary d-inline-block h-100 ma-0 pa-0" dark style="height: 48px">
      <v-spacer></v-spacer>
      <v-divider vertical></v-divider>
      <v-menu>
        <template v-slot:activator="{ on }">
          <v-btn text class="text-capitalize" v-on="on">
            {{ user === null ? '' : user.userName }}
            <v-icon v-if="isUserWithMisconfiguredGroup" right color="red lighten-1">mdi-alert-circle</v-icon>
            <v-icon v-else right>mdi-account-circle</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-if="user.admin" v-on:click="redirectZadmin">
            <v-list-item-icon>
              <v-icon>mdi-application-cog-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Z-Admin</v-list-item-title>
          </v-list-item>
          <v-list-item v-on:click="openInfoTab">
            <v-list-item-icon>
              <v-icon>mdi-information-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('common.info') }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-on:click="logout">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('common.logout') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main>
      <v-container fluid class="pa-0">
        <v-row no-gutters class="d-block d-md-flex" justify="center">
          <v-col cols="12" md="4" class="text-center primary d-none d-md-block">
            <img src="@/assets/logo-zenon-page.png" style="width: 100%">
          </v-col>
          <v-col cols="12" md="4" class="text-center d-md-none">
            <img src="@/assets/logo-zenon-small.png">
          </v-col>
          <v-col cols="12" md="8">
            <v-row align="center" class="ma-0">
              <v-col cols="12" :class="responsiveClass">
                <v-row class="my-8" style="height: 120px">
                  <v-col cols="2" class="text-center">
                    <div style="height: 100px">
                      <img :src="formData.radio.logoUrl" style="max-height: 100px; max-width: 100px;"
                           v-if="formData.radio && formData.radio.logoUrl"/>
                    </div>
                  </v-col>
                  <v-col cols="7">
                    <v-row>
                      <v-col cols="6">
                        <v-select clearable dense outlined item-text="label" item-value="id" return-object
                                  v-bind:label="$t('common.radio')"
                                  v-bind:items="radios" v-model="formData.radio"
                                  v-on:change="selectRadio"></v-select>
                      </v-col>
                      <v-col cols="6">
                        <v-select clearable dense outlined item-text="label" item-value="id"
                                  v-bind:label="$t('common.site')"
                                  v-bind:items="sites" v-model="formData.siteId"
                                  v-on:change="selectSite"></v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="3">
                    <v-checkbox
                      class="mt-0 mb-6 storeSelection" dense
                      :value="currentSelectionIsStored"
                      :label="(!preselectedContextHaveRadioAndSite || currentSelectionIsStored) ? $t('components.home.saveSelectedRadio') : $t('components.home.replaceSavedRadio')"
                      @change="savePreselection"
                      :disabled="!(formData.radio && formData.siteId)"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-row v-if="isLoading" class="mt-0 ml-0 mr-0">
                  <v-col cols="12" class="text-center"
                         v-show="isLoading">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </v-col>
                </v-row>
                <div v-if="instances.length" class="listApplication">
                  <v-row v-if="instances.length" class="mt-0 ml-0 mr-0">
                    <v-col cols="12" class="text-center"
                           v-show="isLoading">
                      <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </v-col>
                    <v-col cols="6" class="text-center pa-1"
                           v-show="!isLoading"
                           v-for="instance in instances" v-bind:key="instance.id">
                      <v-btn text height="56" width="60%" class="justify-space-between" @click="wopen(instance)">
                        <v-img :src="instance.iconUrl" max-height="45" max-width="45" contain></v-img>
                        {{ instance.label }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import API from '@/services/api'
import { mapState } from 'vuex'

export default {
  data: function () {
    return {
      radios: [],
      sites: [],
      instances: [],
      formData: {
        radio: null,
        siteId: null
      },
      isLoading: false
    }
  },
  computed: {
    ...mapState({
      user: state => state.user,
      groupsWithRadioLinks: state => state.groupsWithRadioLinks,
      isUserWithMisconfiguredGroup: state => state.isUserWithMisconfiguredGroup,
      config: state => state.config,
      preselectedContextRadioSite: state => state.preselectedContextRadioSite
    }),
    groupId () {
      return this.groupsWithRadioLinks[this.formData.radio.id]
    },
    responsiveClass () {
      // Check if the current breakpoint is 'md'
      return this.$vuetify.breakpoint.mdAndUp ? 'mdUp-calculatedHeight' : 'mdDown-calculatedHeight'
    },
    preselectedContextHaveRadioAndSite () {
      return this.preselectedContextRadioSite.radioId && this.preselectedContextRadioSite.siteId
    },
    currentSelectionIsStored () {
      return this.preselectedContextHaveRadioAndSite && this.preselectedContextRadioSite.radioId === this.formData.radio?.id && this.preselectedContextRadioSite.siteId === this.formData.siteId
    }
  },
  created () {
    API.getRadios().then(r => {
      this.radios = r.data.filter(radio =>
        (this.config.radioIds?.length ?? 0) === 0 ||
        this.config.radioIds.includes(radio.id))
      // check first if existing localstorage for preselected radio and site before auto select alone value
      if (this.preselectedContextHaveRadioAndSite && this.radios.some(radio => radio.id === this.preselectedContextRadioSite.radioId)) {
        this.formData.radio = this.radios.find(r => r.id === this.preselectedContextRadioSite.radioId)
        this.checkPreselectedSite(this.preselectedContextRadioSite.radioId, this.preselectedContextRadioSite.siteId)
      } else if (this.radios && this.radios.length === 1) {
        this.formData.radio = this.radios[0]
        this.selectRadio()
      }
    })
  },
  methods: {
    getInstances () {
      this.isLoading = true
      API.getInstances(this.formData.radio.id, this.formData.siteId, this.groupId).then(r => {
        this.instances = r.data.sort((a, b) => {
          if (a.orderNum === null && b.orderNum === null) {
            return 0
          }
          if (a.orderNum === null) {
            return 1
          }
          if (b.orderNum === null) {
            return -1
          }
          return a.orderNum - b.orderNum
        })
      }).finally(() => {
        this.isLoading = false
      })
    },
    // Check is the saved preselected site still exist, if yes, save sites
    checkPreselectedSite (radioId, siteId) {
      API.getSites(radioId).then(r => {
        if (r.data.some(site => site.id === siteId)) {
          this.sites = r.data.sort((f1, f2) => f1.label.localeCompare(f2.label))
          this.formData.siteId = siteId
          this.getInstances()
        } else {
          this.formData.radio = null
          this.formData.siteId = null
        }
      })
    },
    selectRadio () {
      this.formData.siteId = null
      this.sites = []
      this.instances = []

      if (this.formData.radio) {
        API.getSites(this.formData.radio.id).then(r => {
          this.sites = r.data.sort((f1, f2) => f1.label.localeCompare(f2.label))
          if (this.sites && this.sites.length === 1) {
            this.formData.siteId = this.sites[0].id
            this.getInstances()
          }
        })
      }
    },
    selectSite () {
      this.instances = []
      if (this.formData.siteId) this.getInstances()
    },
    logout () {
      this.$store.dispatch('logout')
    },
    redirectZadmin () {
      window.open(this.config.zadminUrl, '_blank')
    },
    wopen (instance) {
      API.retrieveLaunchUrl(instance, this.formData.radio.id, this.formData.siteId, this.groupId).then(r => {
        if (instance.webApp) {
          this.$cookies.set('LoginAD', this.user.login, 10, null, null, null, 'Strict')
          window.open(r.data, '_blank')
        } else {
          window.open(r.data)
        }
      })
    },
    openInfoTab () {
      window.open(this.config.urlApplication + '/info', '_blank')
    },
    savePreselection (value) {
      if (value) {
        this.$store.dispatch('updatePreselectedContextRadioSite', {
          radioId: this.formData.radio?.id,
          siteId: this.formData.siteId
        })
      } else {
        // delete key and value in local storage
        this.$store.commit('deletePreselectedContextRadioSite')
      }
    }
  }
}
</script>
<style scoped>
.mdUp-calculatedHeight {
  height: calc(100vh - 48px);
}

.mdDown-calculatedHeight {
  height: calc(100vh - 48px - 145px);
}

.listApplication {
  border-width: 1px 0; /* Top and bottom 2px, left and right 0 */
  border-style: solid;
  border-color: lightgray;
  height: calc(100% - 200px);
  overflow-y: auto;
}

.storeSelection::v-deep .v-label {
  font-size: 0.8em;
}
</style>
